import { Rule } from './Rule';
import { validateRuntimeOptions, RuntimeTypes } from '../runtimeOptionChecker';
class Counter extends Rule {
    constructor(options) {
        var _a, _b, _c;
        super(options);
        this.selector = '*';
        this.counterValue = 0;
        this.incrementEl = (_a = options.incrementEl) !== null && _a !== void 0 ? _a : '';
        this.resetEl = (_b = options.resetEl) !== null && _b !== void 0 ? _b : '';
        this.replaceEl = (_c = options.replaceEl) !== null && _c !== void 0 ? _c : '';
        validateRuntimeOptions(options, {
            name: 'Counter',
            replaceEl: RuntimeTypes.string,
            resetEl: RuntimeTypes.string,
            incrementEl: RuntimeTypes.string,
            replace: RuntimeTypes.func,
        });
    }
    setup() {
        this.counterValue = 0;
    }
    beforeAdd(el) {
        if (this.incrementEl.length && el.matches(this.incrementEl))
            this.counterValue += 1;
        if (this.resetEl.length && el.matches(this.resetEl))
            this.counterValue = 0;
        if (this.replaceEl.length && el.matches(this.replaceEl))
            return this.createReplacement(el);
        return el;
    }
    createReplacement(element) {
        return this.replace(element, this.counterValue);
    }
    replace(element, counterValue) {
        element.textContent = `${counterValue}`;
        return element;
    }
}
export default Counter;
