import { stylesheet } from '../dom';
import { parseLength } from '../utils';
import defaultPageSetup from './defaultPageSetup';
import { SheetSize, SheetLayout } from '../constants';
const letter = Object.freeze({ width: '8.5in', height: '11in' });
const a4 = Object.freeze({ width: '210mm', height: '297mm' });
// Not a really reliable way to know this
const supportsCustomPageSize = () => !!window.hasOwnProperty('chrome');
class PageSetup {
    constructor(opts = {}, printOpts = {}) {
        var _a, _b, _c;
        this.size = (_a = opts.size) !== null && _a !== void 0 ? _a : defaultPageSetup.size;
        this.margin = (_b = opts.margin) !== null && _b !== void 0 ? _b : defaultPageSetup.margin;
        this.markLength = '12pt';
        this.paper = supportsCustomPageSize()
            ? printOpts.paper || SheetSize.AUTO
            : SheetSize.AUTO_MARKS;
        this.bleed = (_c = printOpts.bleed) !== null && _c !== void 0 ? _c : defaultPageSetup.bleed;
        this.printTwoUp =
            !!printOpts.layout && printOpts.layout !== SheetLayout.PAGES;
    }
    get displaySize() {
        const width = this.printTwoUp ? this.spreadSize.width : this.size.width;
        const height = this.size.height;
        const bleed = this.bleed;
        return { width, height, bleed };
    }
    get sheetSize() {
        const width = this.printTwoUp ? this.spreadSize.width : this.size.width;
        const height = this.size.height;
        const doubleBleed = `2 * ${this.bleed}`;
        const doubleMarks = `${doubleBleed} + 2 * ${this.markLength}`;
        const singleMarks = `${this.bleed} + ${this.markLength}`;
        switch (this.paper) {
            case SheetSize.AUTO:
                return { width, height };
            case SheetSize.AUTO_BLEED:
                return {
                    width: `calc(${width} + ${this.printTwoUp ? doubleBleed : this.bleed})`,
                    height: `calc(${height} + ${doubleBleed})`,
                };
            case SheetSize.AUTO_MARKS:
                return {
                    width: `calc(${width} + ${this.printTwoUp ? doubleMarks : singleMarks})`,
                    height: `calc(${height} + ${doubleMarks})`,
                };
            case SheetSize.LETTER_LANDSCAPE:
                return { width: letter.height, height: letter.width };
            case SheetSize.LETTER_PORTRAIT:
                return letter;
            case SheetSize.A4_PORTRAIT:
                return a4;
            case SheetSize.A4_LANDSCAPE:
                return { width: a4.height, height: a4.width };
            default:
                throw Error(`Can't get dimensions for unknown paper size: ${this.paper}`);
        }
    }
    get spreadSize() {
        const w = parseLength(this.size.width);
        return {
            height: this.size.height,
            width: `${w.val * 2}${w.unit}`,
        };
    }
    updateStyleVars() {
        const page = this.size;
        const sheet = this.sheetSize;
        const cssVariables = {
            'spread-width': this.spreadSize.width,
            'page-width': page.width,
            'page-height': page.height,
            'sheet-width': sheet.width,
            'sheet-height': sheet.height,
            'margin-inner': this.margin.inner,
            'margin-outer': this.margin.outer,
            'margin-top': this.margin.top,
            'margin-bottom': this.margin.bottom,
            bleed: this.bleed,
            'mark-length': this.markLength,
        };
        const cssStr = Object.entries(cssVariables)
            .map(([k, v]) => {
            return `--bindery-${k}: ${v};`;
        })
            .join('');
        const rootRule = `:root { ${cssStr} }`;
        const pageRule = `@page { size: ${sheet.width} ${sheet.height}; }`;
        stylesheet('binderyPage').innerHTML = `${rootRule} ${pageRule}`;
    }
}
export default PageSetup;
