const validateRuntimeOptions = (opts, validOpts) => {
    if (!validOpts)
        throw Error('Valid options not specified');
    Object.keys(opts).forEach(k => {
        var _a;
        if (!validOpts[k]) {
            const setName = (_a = validOpts.name) !== null && _a !== void 0 ? _a : 'This option';
            throw Error(`Unknown option in ${setName}: '${k}'`);
        }
        const val = opts[k];
        const type = validOpts[k];
        if (!type.check(val)) {
            const optName = validOpts.name ? `${validOpts.name}.${k}` : k;
            const valName = JSON.stringify(val);
            throw Error(`Invalid value for '${optName}': ${valName} is not a ${type.name}.`);
        }
    });
    return true;
};
export default validateRuntimeOptions;
