/* global BINDERY_VERSION */
// main
import PageSetup from './page-setup';
import { ViewerMode, SheetSize, SheetLayout, SheetMarks } from './constants';
import defaultRules from './defaults';
// components
import makeBook from './makeBook';
import { getContentAsElement } from './makeBook/getContent';
import Viewer from './viewer';
import rules from './rules';
import { validateRuntimeOptions, RuntimeTypes } from './runtimeOptionChecker';
import { Rule } from './rules/Rule';
const vals = (obj) => {
    return Object.keys(obj).map(k => obj[k]);
};
const nextFrame = () => new Promise(resolve => {
    requestAnimationFrame(t => resolve(t));
});
class Bindery {
    constructor(opts) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        console.log(`📖 Bindery ${BINDERY_VERSION}`);
        validateRuntimeOptions(opts, {
            name: 'makeBook',
            autorun: RuntimeTypes.bool,
            content: RuntimeTypes.any,
            view: RuntimeTypes.enum(...vals(ViewerMode)),
            pageNumberOffset: RuntimeTypes.number,
            pageSetup: RuntimeTypes.shape({
                name: 'pageSetup',
                margin: RuntimeTypes.margin,
                size: RuntimeTypes.size,
            }),
            printSetup: RuntimeTypes.shape({
                name: 'printSetup',
                bleed: RuntimeTypes.length,
                layout: RuntimeTypes.enum(...vals(SheetLayout)),
                marks: RuntimeTypes.enum(...vals(SheetMarks)),
                paper: RuntimeTypes.enum(...vals(SheetSize)),
            }),
            rules: RuntimeTypes.array,
        });
        this.autorun = (_a = opts.autorun) !== null && _a !== void 0 ? _a : true;
        this.autoupdate = (_b = opts.autoupdate) !== null && _b !== void 0 ? _b : false;
        this.pageSetup = new PageSetup(opts.pageSetup, opts.printSetup);
        const startLayout = (_d = (_c = opts.printSetup) === null || _c === void 0 ? void 0 : _c.layout) !== null && _d !== void 0 ? _d : SheetLayout.PAGES;
        const startMarks = (_f = (_e = opts.printSetup) === null || _e === void 0 ? void 0 : _e.marks) !== null && _f !== void 0 ? _f : SheetMarks.CROP;
        this.viewer = new Viewer({
            pageSetup: this.pageSetup,
            mode: (_g = opts.view) !== null && _g !== void 0 ? _g : ViewerMode.PREVIEW,
            marks: startMarks,
            layout: startLayout,
        });
        if (!opts.content) {
            this.viewer.displayError('Content not specified', 'You must include a source element, selector, or url');
            throw Error('Bindery: You must include a source element or selector');
        }
        if (opts.hasOwnProperty('ControlsComponent')) {
            this.viewer.displayError('Controls are now included', 'Please remove the controls component');
            throw Error('Bindery: controls are now included');
        }
        this.rules = defaultRules;
        this.rules.push(new Rule({ pageNumberOffset: (_h = opts.pageNumberOffset) !== null && _h !== void 0 ? _h : 0 }));
        (_j = opts.rules) === null || _j === void 0 ? void 0 : _j.forEach(rule => {
            if (rule instanceof rules.Rule) {
                this.rules.push(rule);
            }
            else {
                throw Error(`Bindery: The following is not an instance of Bindery.Rule and will be ignored: ${rule}`);
            }
        });
        if (this.autorun)
            this.makeBook(opts.content);
    }
    // Convenience constructor
    static makeBook(opts) {
        var _a;
        opts.autorun = (_a = opts.autorun) !== null && _a !== void 0 ? _a : true;
        return new Bindery(opts);
    }
    cancel() {
        this.viewer.hide();
        if (this.content)
            this.content.style.display = '';
    }
    async makeBook(contentDescription) {
        try {
            this.content = await getContentAsElement(contentDescription);
        }
        catch (e) {
            this.viewer.show();
            this.viewer.displayError('', e.message);
            // throw e;
            return undefined;
        }
        this.content.style.display = '';
        const content = this.content.cloneNode(true);
        this.content.style.display = 'none';
        this.viewer.clear(); // In case we're updating an existing layout
        this.viewer.show();
        this.pageSetup.updateStyleVars();
        this.viewer.setInProgress(true);
        const onProgress = (currentBook, progress) => {
            this.viewer.updateProgress(currentBook, progress);
        };
        try {
            const book = await makeBook(content, this.rules, onProgress);
            this.viewer.setProgressAmount(1);
            await nextFrame();
            this.viewer.render(book);
            this.viewer.setInProgress(false);
            return book;
        }
        catch (e) {
            this.viewer.setInProgress(false);
            this.viewer.displayError("Layout couldn't complete", e.message);
            // throw e;
            return undefined;
        }
    }
}
export default Bindery;
