import { Rule } from './Rule';
import { validateRuntimeOptions, RuntimeTypes } from '../runtimeOptionChecker';
class Split extends Rule {
    constructor(options) {
        super(options);
        this.toNext = options.toNext;
        this.fromPrevious = options.fromPrevious;
        validateRuntimeOptions(options, {
            name: 'Split',
            selector: RuntimeTypes.string,
            toNext: RuntimeTypes.string,
            fromPrevious: RuntimeTypes.string,
            didSplit: RuntimeTypes.func,
        });
    }
    didSplit(original, clone) {
        if (this.toNext)
            original.classList.add(this.toNext);
        if (this.fromPrevious)
            clone.classList.add(this.fromPrevious);
    }
}
export default Split;
