export const classPrefix = BINDERY_CLASS_PREFIX;
export var SheetLayout;
(function (SheetLayout) {
    SheetLayout["PAGES"] = "pages";
    SheetLayout["SPREADS"] = "spreads";
    SheetLayout["BOOKLET"] = "booklet";
})(SheetLayout || (SheetLayout = {}));
export var SheetMarks;
(function (SheetMarks) {
    SheetMarks[SheetMarks["NONE"] = 0] = "NONE";
    SheetMarks[SheetMarks["CROP"] = 1] = "CROP";
    SheetMarks[SheetMarks["BLEED"] = 2] = "BLEED";
    SheetMarks[SheetMarks["BOTH"] = 3] = "BOTH";
})(SheetMarks || (SheetMarks = {}));
export var SheetSize;
(function (SheetSize) {
    SheetSize["AUTO"] = "auto";
    SheetSize["AUTO_BLEED"] = "auto-bleed";
    SheetSize["AUTO_MARKS"] = "auto-marks";
    SheetSize["LETTER_PORTRAIT"] = "letter-portrait";
    SheetSize["LETTER_LANDSCAPE"] = "letter-landscape";
    SheetSize["A4_PORTRAIT"] = "a4-portrait";
    SheetSize["A4_LANDSCAPE"] = "a4-landscape";
})(SheetSize || (SheetSize = {}));
export var ViewerMode;
(function (ViewerMode) {
    ViewerMode["FLIPBOOK"] = "flipbook";
    ViewerMode["PREVIEW"] = "preview";
    ViewerMode["PRINT"] = "print";
})(ViewerMode || (ViewerMode = {}));
