export const annotatePagesNumbers = (pages, offset) => {
    // ———
    // NUMBERING
    // TODO: Pass in facingpages as an option?
    const facingPages = true;
    if (facingPages) {
        pages.forEach((page, i) => {
            page.number = offset + i + 1;
            page.setLeftRight(i % 2 === 0 ? 'right' : 'left');
        });
    }
    else {
        pages.forEach(page => {
            page.setLeftRight('right');
        });
    }
};
export const annotatePagesHierarchy = (pages, headerSelectorHierarchy) => {
    // ———
    // RUNNING HEADERS
    // Sections to annotate with.
    // This should be a hierarchical list of selectors.
    // Every time one is selected, it annotates all following pages
    // and clears any subselectors.
    let currentHierarchy = [];
    pages.forEach(page => {
        const pageHierarchy = [];
        headerSelectorHierarchy.forEach((selector, i) => {
            var _a;
            const element = page.element.querySelector(selector);
            // A new header level starts on this page
            if (element) {
                currentHierarchy[i] = {
                    selector: selector,
                    text: (_a = element.textContent) !== null && _a !== void 0 ? _a : '',
                    el: element,
                };
                // Clear any lower headers in the hierarchy
                currentHierarchy = currentHierarchy.slice(0, i + 1);
                // headerSelectorHierarchy.forEach((lowerSelector, j) => {
                //   if (j > i) {
                //     currentHeaders[j] = { selector: lowerSelector, text: '', el: undefined };
                //   }
                // });
            }
            // Always decorate this page with current header state.
            if (currentHierarchy[i]) {
                pageHierarchy[i] = currentHierarchy[i];
            }
        });
        page.hierarchy = pageHierarchy;
    });
};
export const annotatePages = (pages, offset) => {
    annotatePagesNumbers(pages, offset);
    annotatePagesHierarchy(pages, ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']);
};
