import { classes, classForMode, allModeClasses } from './classes';
import prefixer from './prefixer';
import safeMeasure from './safeMeasure';
export * from './dom';
// Create stylesheet with id
const addStylesheet = (id) => {
    const style = window.document.createElement('style');
    style.id = id;
    window.document.head.appendChild(style);
    return style;
};
// Fetch or create stylesheet with id
const stylesheet = (id) => {
    var _a;
    return (_a = window.document.querySelector(`#${id}`)) !== null && _a !== void 0 ? _a : addStylesheet(id);
};
// Parse html from text
const parseHTML = (text, selector) => {
    const wrapper = window.document.createElement('div');
    wrapper.innerHTML = text;
    return selector ? wrapper.querySelector(selector) : wrapper;
};
export { prefixer, classes, classForMode, allModeClasses, stylesheet, safeMeasure, parseHTML, };
