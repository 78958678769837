import { Rule } from './Rule';
import { validateRuntimeOptions, RuntimeTypes } from '../runtimeOptionChecker';
class PageBreak extends Rule {
    constructor(options) {
        var _a, _b;
        super(options);
        if (options.continue == 'same') {
            throw Error("Can't continue on the same pager after a Page Break ");
        }
        this.continue = (_a = options.continue) !== null && _a !== void 0 ? _a : 'next';
        this.position = (_b = options.position) !== null && _b !== void 0 ? _b : 'before';
        validateRuntimeOptions(options, {
            name: 'PageBreak',
            selector: RuntimeTypes.string,
            continue: RuntimeTypes.enum('next', 'left', 'right'),
            position: RuntimeTypes.enum('before', 'after', 'both', 'avoid'),
        });
    }
    get avoidSplit() {
        return this.position === 'avoid';
    }
    beforeAdd(elmt, book, continueOnNewPage) {
        if (this.position === 'before' || this.position === 'both') {
            if (!book.currentPage.isEmpty) {
                continueOnNewPage();
            }
            if (this.continue !== 'next') {
                book.currentPage.setPreference(this.continue);
            }
        }
        return elmt;
    }
    afterAdd(elmt, book, continueOnNewPage) {
        if (this.position === 'after' || this.position === 'both') {
            continueOnNewPage();
            if (this.continue !== 'next') {
                book.currentPage.setPreference(this.continue);
            }
        }
        return elmt;
    }
}
export default PageBreak;
