import Replace from './Replace';
import { validateRuntimeOptions, RuntimeTypes } from '../runtimeOptionChecker';
import { div } from '../dom';
class Footnote extends Replace {
    constructor(options) {
        super(options);
        validateRuntimeOptions(options, {
            name: 'Footnote',
            selector: RuntimeTypes.string,
            replace: RuntimeTypes.func,
            render: RuntimeTypes.func,
        });
    }
    afterAdd(element, book, continueOnNewPage, makeNewPage, overflowCallback) {
        const number = book.currentPage.footer.children.length + 1;
        const footnote = div('.footnote');
        const contents = this.render(element, number);
        if (contents instanceof HTMLElement)
            footnote.appendChild(contents);
        else
            footnote.innerHTML = contents;
        book.currentPage.footer.appendChild(footnote);
        return super.afterAdd(element, book, continueOnNewPage, makeNewPage, (overflowEl) => {
            book.currentPage.footer.removeChild(footnote);
            return overflowCallback(overflowEl);
        });
    }
    createReplacement(book, element) {
        const number = book.currentPage.footer.children.length;
        return this.replace(element, number);
    }
    replace(element, number) {
        element.insertAdjacentHTML('beforeend', `<sup class="bindery-sup">${number}</sup>`);
        return element;
    }
    render(originalElement, number) {
        return `<sup>${number}</sup> Default footnote (<a href='/bindery/docs/#footnote'>Learn how to change it</a>)`;
    }
}
export default Footnote;
