import OutOfFlow from './OutOfFlow';
import { validateRuntimeOptions, RuntimeTypes } from '../runtimeOptionChecker';
import { div } from '../dom';
class FullBleedPage extends OutOfFlow {
    constructor(options) {
        var _a, _b;
        options.continue = (_a = options.continue) !== null && _a !== void 0 ? _a : 'same';
        options.rotate = (_b = options.rotate) !== null && _b !== void 0 ? _b : 'none';
        super(options);
        validateRuntimeOptions(options, {
            name: 'FullBleedPage',
            selector: RuntimeTypes.string,
            continue: RuntimeTypes.enum('next', 'same', 'left', 'right'),
            rotate: RuntimeTypes.enum('none', 'inward', 'outward', 'clockwise', 'counterclockwise'),
        });
    }
    createOutOfFlowPages(elmt, book, makeNewPage) {
        if (elmt.parentNode) {
            elmt.parentNode.removeChild(elmt);
        }
        let newPage;
        if (book.currentPage.isEmpty) {
            newPage = book.currentPage;
        }
        else {
            newPage = makeNewPage();
            book.addPage(newPage);
        }
        if (this.rotate !== 'none') {
            const rotateContainer = div(`.rotate-container.page-size-rotated.rotate-${this.rotate}`);
            rotateContainer.appendChild(newPage.background);
            newPage.element.appendChild(rotateContainer);
        }
        newPage.background.appendChild(elmt);
        newPage.hasOutOfFlowContent = true;
    }
}
export default FullBleedPage;
