import rules from '../rules';
const { PageBreak, PageReference, Footnote, FullBleedPage, FullBleedSpread, } = rules;
const replacer = (element, number) => {
    element.textContent = `${number}`;
    return element;
};
export default [
    PageBreak({ selector: '[book-page-break="both"]', position: 'both' }),
    PageBreak({ selector: '[book-page-break="avoid"]', position: 'avoid' }),
    PageBreak({
        selector: '[book-page-break="after"][book-page-continue="right"]',
        position: 'after',
        continue: 'right',
    }),
    PageBreak({
        selector: '[book-page-break="after"][book-page-continue="left"]',
        position: 'after',
        continue: 'left',
    }),
    PageBreak({
        selector: '[book-page-break="after"][book-page-continue="next"]',
        position: 'after',
        continue: 'next',
    }),
    PageBreak({
        selector: '[book-page-break="before"][book-page-continue="right"]',
        position: 'before',
        continue: 'right',
    }),
    PageBreak({
        selector: '[book-page-break="before"][book-page-continue="left"]',
        position: 'before',
        continue: 'left',
    }),
    PageBreak({
        selector: '[book-page-break="before"][book-page-continue="next"]',
        position: 'before',
        continue: 'next',
    }),
    FullBleedPage({ selector: '[book-full-bleed="page"]' }),
    FullBleedSpread({ selector: '[book-full-bleed="spread"]' }),
    Footnote({
        selector: '[book-footnote-text]',
        render: (element, number) => {
            const txt = element.getAttribute('book-footnote-text');
            return `<i>${number}</i>${txt}`;
        },
    }),
    PageReference({
        selector: '[book-pages-with-text]',
        replace: replacer,
        createTest: (element) => {
            var _a;
            const text = (_a = element.getAttribute('book-pages-with-text')) !== null && _a !== void 0 ? _a : '';
            const term = text.toLowerCase().trim();
            return (pageElement) => {
                const pageText = pageElement.textContent || '';
                return pageText.toLowerCase().includes(term);
            };
        },
    }),
    PageReference({
        selector: '[book-pages-with-selector]',
        replace: replacer,
        createTest: (element) => {
            var _a;
            const txt = (_a = element.getAttribute('book-pages-with-selector')) !== null && _a !== void 0 ? _a : '';
            const selector = txt.trim();
            return (pageElement) => {
                return !!pageElement.querySelector(selector);
            };
        },
    }),
    PageReference({
        selector: '[book-pages-with]',
        replace: replacer,
        createTest: (element) => {
            var _a;
            const text = (_a = element.textContent) !== null && _a !== void 0 ? _a : '';
            const term = text.toLowerCase().trim();
            return (pageElement) => {
                var _a;
                const pageText = (_a = pageElement.textContent) !== null && _a !== void 0 ? _a : '';
                return pageText.toLowerCase().includes(term);
            };
        },
    }),
];
