export class Rule {
    constructor(options) {
        var _a;
        this.name = (_a = options === null || options === void 0 ? void 0 : options.name) !== null && _a !== void 0 ? _a : 'Unnamed Bindery Rule';
        this.selector = '';
        Object.keys(options).forEach(key => {
            this[key] = options[key];
        });
    }
    setup() { }
}
