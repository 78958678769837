import { ViewerMode, SheetSize } from '../constants';
import { getSheetSizeLabels, marksLabels, modeLabels, layoutLabels, } from './labels';
import { btn, row, div, enumDropdown } from './components';
const renderPrintOptions = (state, actions) => {
    const shouldShowMarks = state.paper !== SheetSize.AUTO && state.paper !== SheetSize.AUTO_BLEED;
    const sizeLabels = getSheetSizeLabels(state.pageSize);
    return row('.print-options', row(null, enumDropdown('bindery-choose-layout', layoutLabels, state.layout, actions.setLayout)), row(null, enumDropdown('bindery-choose-paper', sizeLabels, state.paper, actions.setPaper)), shouldShowMarks
        ? row(null, enumDropdown('bindery-choose-marks', marksLabels, state.marks, actions.setMarks))
        : '');
};
class Controls {
    constructor() {
        this.element = div('.controls');
    }
    update(state, actions) {
        var _a, _b;
        const oldElement = this.element;
        const newElement = this.render(state, actions);
        const focusedId = document.hasFocus() ? (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.id : undefined;
        oldElement.replaceWith(newElement);
        if (focusedId)
            (_b = document.getElementById(focusedId)) === null || _b === void 0 ? void 0 : _b.focus();
        this.element = newElement;
    }
    render(state, actions) {
        const print = () => {
            actions.setMode(ViewerMode.PRINT);
            setTimeout(window.print, 10);
        };
        const shouldShowPrint = state.mode === ViewerMode.PRINT;
        return div('.controls', row('.view-row', enumDropdown('bindery-choose-view', modeLabels, state.mode, actions.setMode)), shouldShowPrint ? renderPrintOptions(state, actions) : '', btn('.btn-print.btn-main', { onclick: print }, 'Print'));
    }
}
export default Controls;
