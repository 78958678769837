const pageNumbersForTest = (pages, test) => {
    return pages
        .filter(pg => !!pg.number)
        .filter(pg => test(pg.element))
        .map(pg => pg.number);
};
const pageNumbersForSelector = (pages, selector) => {
    return pageNumbersForTest(pages, (el) => {
        return !!el.querySelector(selector);
    });
};
export { pageNumbersForTest, pageNumbersForSelector };
