import { div, button, select, option } from '../dom';
const row = (cls, ...children) => {
    return div(`${cls}.row`, ...children);
};
// Button
const btn = (cls, attrs, label) => {
    return button(`.control.btn${cls}`, attrs, label);
};
const dropdown = (attrs, options) => {
    const selectVal = div('.select-val', 'Value');
    const selectEl = select('.select', attrs, ...options);
    selectVal.textContent = selectEl.options[selectEl.selectedIndex].text;
    return div('.select-wrap.control', selectVal, selectEl);
};
const enumDropdown = (id, entries, initialValue, changeHandler) => {
    const eventHandler = (e) => {
        const rawVal = e.target.value;
        const chosenEntry = entries.filter(entry => entry[0].toString() === rawVal)[0];
        if (chosenEntry) {
            changeHandler(chosenEntry[0]);
        }
        else {
            throw Error('Selected unknown value');
        }
    };
    return dropdown({ onchange: eventHandler, id }, entries.map(entry => {
        const el = option({ value: entry[0] }, entry[1]);
        if (entry[0] === initialValue) {
            el.selected = true;
        }
        return el;
    }));
};
export { row, btn, dropdown, option, div, enumDropdown };
