import { Rule } from './Rule';
import { validateRuntimeOptions, RuntimeTypes } from '../runtimeOptionChecker';
import { div } from '../dom';
// TODO selectorHierarchy: [ String ], ie [ 'h1', 'h2', 'h3.chapter' ]
class RunningHeader extends Rule {
    constructor(options = {}) {
        super(options);
        validateRuntimeOptions(options, {
            name: 'RunningHeader',
            render: RuntimeTypes.func,
        });
    }
    eachPage(page) {
        if (!page.runningHeader) {
            const elmt = div('.running-header');
            page.element.appendChild(elmt);
            page.runningHeader = elmt;
        }
        page.runningHeader.innerHTML = this.render(page);
    }
    render(page) {
        return `${page.number}`;
    }
}
export default RunningHeader;
