const throttleFrame = () => {
    let wasCalled = false;
    let queued;
    const inner = (func) => {
        if (wasCalled) {
            queued = func;
            return;
        }
        wasCalled = true;
        func();
        requestAnimationFrame(() => {
            wasCalled = false;
            if (queued) {
                const queuedFunc = queued;
                queued = undefined;
                inner(queuedFunc);
            }
        });
    };
    return inner;
};
const throttleTime = (ms) => {
    let wasCalled = false;
    let queued;
    const inner = (func) => {
        if (wasCalled) {
            queued = func;
            return;
        }
        wasCalled = true;
        func();
        setTimeout(() => {
            wasCalled = false;
            if (queued) {
                const queuedFunc = queued;
                queued = undefined;
                inner(queuedFunc);
            }
        }, ms);
    };
    return inner;
};
export { throttleFrame, throttleTime };
