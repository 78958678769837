import prefixer from './prefixer';
const isElement = (node) => node.nodeType === Node.ELEMENT_NODE;
const isObj = (val) => typeof val === 'object';
const isFunc = (val) => typeof val === 'function';
const isStr = (val) => typeof val === 'string';
const isElementWrapper = (val) => (val === null || val === void 0 ? void 0 : val.element) && isElement(val.element);
export const h = (tagName, classNames, attrs, ...children) => {
    const el = document.createElement(tagName);
    if (classNames)
        el.className = classNames
            .split('.')
            .filter(txt => txt !== '')
            .map(prefixer)
            .join(' ');
    if (attrs)
        for (const k in attrs) {
            // @ts-ignore TODO replace with hyperscript anyways
            const v = attrs[k];
            // @ts-ignore TODO replace with hyperscript anyways
            if (isFunc(v))
                el[k] = v;
            else
                el.setAttribute(k, v);
        }
    if (children) {
        el.append(...children.map(item => {
            return isElementWrapper(item) ? item.element : item;
        }));
    }
    return el;
};
export const div = (cls, ...children) => {
    return h('div', cls, {}, ...children);
};
export const button = (cls, attrs, label) => {
    return h('button', cls, attrs, label);
};
export const select = (cls, attrs, ...optionElements) => {
    return h('select', cls, attrs, ...optionElements);
};
export const option = (attrs, label) => {
    return h('option', null, attrs, label);
};
