export default {
    bleed: '12pt',
    size: { width: '4in', height: '6in' },
    margin: {
        inner: '24pt',
        outer: '24pt',
        bottom: '40pt',
        top: '48pt',
    },
};
