import Replace from './Replace';
import { formatAsRanges } from '../utils';
import { pageNumbersForTest } from '../book';
import { shallowEqual, throttleTime } from '../utils';
import { validateRuntimeOptions, RuntimeTypes } from '../runtimeOptionChecker';
import { prefixer } from '../dom';
// Compatible with ids that start with numbers
const startsWithNumber = (sel) => {
    return sel.length > 2 && sel[0] === '#' && /^\d+$/.test(sel[1]);
};
const safeIDSel = (sel) => {
    return startsWithNumber(sel) ? `[id="${sel.replace('#', '')}"]` : sel;
};
class PageReference extends Replace {
    constructor(options) {
        super(options);
        validateRuntimeOptions(options, {
            name: 'PageReference',
            selector: RuntimeTypes.string,
            replace: RuntimeTypes.func,
            createTest: RuntimeTypes.func,
        });
        this.references = [];
        const throttle = throttleTime(10);
        this.throttledUpdate = book => {
            throttle(() => this.updatePageReferences(book.pages));
        };
    }
    eachPage(page, book) {
        this.throttledUpdate(book);
    }
    afterAdd(elmt, book) {
        const test = this.createTest(elmt);
        if (!test)
            return elmt;
        const ref = this.createReference(book, test, elmt);
        return ref.element;
    }
    createReference(book, testFunction, elmt) {
        const ref = {
            testFunction,
            template: elmt,
            element: elmt,
            previousMatches: undefined,
        };
        this.references.push(ref);
        const currentResults = pageNumbersForTest(book.pages, testFunction);
        this.render(ref, currentResults); // Replace element immediately, to make sure it'll fit
        return ref;
    }
    render(ref, matchingPageNumbers) {
        if (ref.previousMatches && shallowEqual(ref.previousMatches, matchingPageNumbers)) {
            return;
        }
        if (!Array.isArray(matchingPageNumbers)) {
            throw Error('Page search returned unexpected result');
        }
        const hasFoundPage = matchingPageNumbers.length > 0;
        const pageRanges = hasFoundPage ? formatAsRanges(matchingPageNumbers) : '⌧';
        const template = ref.template.cloneNode(true);
        const newRender = this.replace(template, pageRanges);
        if (!hasFoundPage)
            newRender.classList.add(prefixer('placeholder-num'));
        ref.element.parentNode.replaceChild(newRender, ref.element);
        ref.element = newRender;
        ref.previousMatches = matchingPageNumbers;
    }
    createTest(element) {
        const href = element.getAttribute('href');
        if (!href)
            return null;
        const selector = safeIDSel(href);
        return (el) => {
            return !!el.querySelector(selector);
        };
    }
    updatePageReferences(pages) {
        // querySelector first, then rerender
        const results = this.references.map(ref => {
            return { ref, matchingPageNumbers: pageNumbersForTest(pages, ref.testFunction) };
        });
        results.forEach(({ ref, matchingPageNumbers }) => this.render(ref, matchingPageNumbers));
    }
    replace(template, number) {
        template.insertAdjacentHTML('beforeend', `, <span>${number}</span>`);
        return template;
    }
}
export default PageReference;
