import OutOfFlow from './OutOfFlow';
import { validateRuntimeOptions, RuntimeTypes } from '../runtimeOptionChecker';
import { prefixer, div } from '../dom';
class FullBleedSpread extends OutOfFlow {
    constructor(options) {
        var _a, _b;
        options.continue = (_a = options.continue) !== null && _a !== void 0 ? _a : 'same';
        options.rotate = (_b = options.rotate) !== null && _b !== void 0 ? _b : 'none';
        super(options);
        validateRuntimeOptions(options, {
            name: 'FullBleedSpread',
            selector: RuntimeTypes.string,
            continue: RuntimeTypes.enum('next', 'same', 'left', 'right'),
            rotate: RuntimeTypes.enum('none', 'clockwise', 'counterclockwise'),
        });
    }
    createOutOfFlowPages(elmt, book, makeNewPage) {
        if (!!elmt.parentNode) {
            elmt.parentNode.removeChild(elmt);
        }
        let leftPage;
        if (book.currentPage.isEmpty) {
            leftPage = book.currentPage;
        }
        else {
            leftPage = makeNewPage();
            book.addPage(leftPage);
        }
        const rightPage = makeNewPage();
        book.addPage(rightPage);
        if (this.rotate !== 'none') {
            [leftPage, rightPage].forEach(page => {
                const rotateContainer = div(`.rotate-container.spread-size-rotated.rotate-spread-${this.rotate}`);
                rotateContainer.append(page.background);
                page.element.append(rotateContainer);
            });
        }
        leftPage.background.append(elmt);
        leftPage.element.classList.add(prefixer('spread'));
        leftPage.setPreference('left');
        leftPage.isOutOfFlow = this.continue === 'same';
        leftPage.avoidReorder = true;
        leftPage.hasOutOfFlowContent = true;
        rightPage.background.append(elmt.cloneNode(true));
        rightPage.element.classList.add(prefixer('spread'));
        rightPage.setPreference('right');
        rightPage.isOutOfFlow = this.continue === 'same';
        rightPage.avoidReorder = true;
        rightPage.hasOutOfFlowContent = true;
    }
}
export default FullBleedSpread;
